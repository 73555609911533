<template>
  <div class="fs-16" style="line-height: 1.6">
    <div class="my-6" style="text-indent: 2em">
      <div>
        为使用南京亿儒科技有限公司微信小程序服务（以下简称“本服务”或“小程序服务”），您应当阅读并遵守《南京亿儒科技有限公司微信小程序用户协议》，请务必审慎阅读、充分理解各条款内容，特别是免除或限制责任的相应条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。
      </div>
      <div>
        除非您已阅读并接受本条款所有条款，否则您无权使用南京亿儒科技有限公司微信小程序服务。您对本服务的登录、查看、发布信息等行为即视为已阅读并同意本条款的约束。如果您未满18周岁，请在法定监护人的陪同下阅读本您协议，并特别注意未成年人使用条款。
      </div>
    </div>
    <div class="my-6">
      <div class="tc-black fs-25 my-6">一、协议的范围</div>
      <div class="mr-3">
        1.1 本条款是用户（以下简称“你”）与南京亿儒科技有限公司之间关于用户使用小程序服务所订立的协议。“用户”是指注册，登录，使用微信小程序的个人或组织:“其他用户”是指包括其他微信小程序用户、微信公众账号用户和微信用户等除您本人外与小程序服务相关的用户。
      </div>
      <div>
        1.2 本服务是指南京亿儒科技有限公司根据本协议向您提供的服务，包括协助车辆过渡、开具电子发票等。我们会不断丰富您使用本服务的终端、形式等，如您已注册使用一种形式的服务，则可以以同一账号使用其他服务，本协议自动适用于您对所有版本的软件和服务的使用。
      </div>
      <div>
        1.3 小程序属于微信公众账号，在不与本协议冲突的情况下，您应遵守《微信公众平台服务协议》、《腾讯微信软件许可及服务协议》等关于微信公众账号的其他相关规定。
      </div>
      <div>
        1.4 本协议内容包括本协议正文及所有我们已经发布或将来可能发布的隐私权政策、各项政策、规则、声明、通知、警示、提示、说明（以下统称为“用户规则”）。前述用户规则为本协议不可分割的补充部分，与本协议具有同等法律效力。如您使用南京亿儒科技有限公司产品及服务，视为您同意上述补充部分。
      </div>
    </div>
    <div class="my-6">
      <div class="tc-black fs-25 my-6">二、小程序注册与审核</div>
      <div class="tc-black fs-20 my-6">2.1 账号注册</div>
      <div>
        2.1.1 您在使用本服务前需要先进行注册和认证。您确认，在您开始注册使用南京亿儒科技有限公司产品及服务前，您应当是具备完全民事权利能力和与所从事的民事行为相适应的行为能力的自然人、法人或其他组织。若您不具备前述主体资格，请勿使用服务，否则您及您的监护人应承担因此而导致的一切后果，且我们有权注销（永久冻结）您的账户，并向您及您的监护人索偿。
      </div>
      <div>
        2.1.2 当您按照注册页面提示填写信息，阅读并同意本协议且完成全部注册程序后，您可获得南京亿儒科技有限公司平台账户并成为产品用户，即有权使用南京亿儒科技有限公司平台并获得相应服务。南京亿儒科技有限公司平台只允许每位用户使用一个主账户，但您可以在授权范围内获得多个子账号。
      </div>
      <div>
        2.1.3 该账户是您通过南京亿儒科技有限公司平台寻求并获得服务的唯一有效身份证明，请您妥善保管账号和密码，为使您更好地使用南京亿儒科技有限公司平台的各项服务，保障您的账户安全，本小程序主体可要求您按本协议、用户规则及我国法律规定完成实名认证。
      </div>
      <div>
        2.1.4 为维护小程序的健康运营，您应当准确完整地提供您的最新信息（包括您的名称及电子邮件地址、联系电话、联系地址等）。并且您承诺，在使用本服务的过程中提交和发布的信息均是真实、合法的，因不实信息导致南京亿儒科技有限公司或者第三方合法权益受损的，由您独立承担全部责任。
      </div>
      <div class="tc-black fs-20 my-6">2.2 账号安全</div>
      <div>
        2.2.1 在您成功注册后，我们将根据您的身份要素识别您的身份和授权登录。“身份要素”包括但不限干您的账户名称，密码，短信校验码，手机号码，驾驶证，车辆行驶证，身份证件号码及人脸信息，眼纹信息，您同意基于不同的终端以及您的使用习惯，我们可能采取不同的验证措施识别您的身份。
      </div>
      <div>
        2.2.2 如您发现账号遭他人非法使用，应立即通知本小程序主体。因黑客行为或您自身保管疏忽导致账号、密码遭他人非法使用所发生的一切责任，均应由您本人承担，本小程序主体不承担任何责任。
      </div>
      <div>
        2.2.3 您注册成功后，南京亿儒科技有限公司将给予您的帐号授权，任何该账户项下的预订、充值及支付等行为均视为您本人的操作。您应对您账户项下的所有行为结果（包括但不限于授权、绑定、结算）负责。
      </div>
    </div>
    <div class="my-6">
      <div class="tc-black fs-25 my-6">三、服务及规范</div>
      <div class="tc-black fs-20 my-6">3.1 服务内容</div>
      <div>
        3.1.1 本服务内容包含电子发票开具、车辆认证等技术功能，这些功能服务可能根据用户需求的变化，随着因服务版本不同、或服务提供方的单方判断而被优化或修改，或因定期，不定期的维护而暂缓提供。
      </div>
      <div>
        3.1.2 南京亿儒科技有限公司有权自行决定对服务或服务任何部分及其相关功能、应用软件进行变更、升级、修改、转移，并有权决定以适当的方式进行公示或通知。
      </div>
      <div class="tc-black fs-20 my-6">3.2服务费用</div>
      <div>
        3.2.1 南京亿儒科技有限公司平台向您提供的服务目前是免费的。我们保留日后就程序及/或服务向您收费的权利。如果我们决定收取此类费用，我们会采取合理途径并以足够合理的期限提前通过法定程序并以本协议约定的方式通知您，确保您有充分选择的权利。
      </div>
      <div>
        3.2.2 您在使用小程序的过程中，南京亿儒科技有限公司仅为您使用微信小程序服务提供技术支持，南京亿儒科技有限公司不对基于服务而产生的任何行为担保、许可或向任何第三人承担共同责任。
      </div>
      <div class="tc-black fs-20 my-6">3.3服务使用规则</div>
      <div>
        3.3.1 您在本服务中或通过本服务所传送，发布的任何内容并不反映或代表，也不得被视为反映或代表南京亿儒科技有限公司的观点、立场或政策，南京亿儒科技有限公司对此不承担任何责任。
      </div>
      <div>
        3.3.2 您不得利用南京亿儒科技有限公司账号或本服务进行如下行为：（1）提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；（2）强制、诱导其他您关注、点击链接页面或分享信息的；（3）虚构事实、隐瞒真相以误导、欺骗他人的；（4）利用技术手段批量建立虚假账号的；（5）利用平台账号或本服务从事任何违法犯罪活动的；（6）制作、发布与以上行为相关的方法。工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；（7）其他违反法律法规规定、侵犯其他您合法权益、干扰南京亿儒科技有限公司正常运营或南京亿儒科技有限公司未明示授权的行为。
      </div>
      <div>
        3.3.3 南京亿儒科技有限公司有权查阅您的注册、交易数据及交易行为，如发现可能存在违反法律法规、本协议或相关规则的情形或其他任何问题，南京亿儒科技有限公司有权直接做出其认为合理的处理，包括但不限于通知修改、删除相关信息，停止被协议项下的服务内容等。前述约定不代表南京亿儒科技有限公司应对您的行为承担任何连带责任，您应对此产生的法律责任独立负责。
      </div>
    </div>
    <div class="my-6">
      <div class="tc-black fs-25 my-6">四、法律责任</div>
      <div class="tc-black fs-20 my-6">4.1 用户责任</div>
      <div>
        4.1.1 您应遵守《微信公众平台服务协议》中关于“法律责任”的约定，除非该等约定与本协议存在冲突。
      </div>
      <div>
        4.1.2 如果我们发现或收到他人举报或投诉您违反本协议约定的，我们有权不经通知随时对相关内容，包括但不限于对您的资料、聊天记录进行审查、删除，并视情节轻重对违规账号处以包括但不限于警告、账号封禁、功能封禁的处罚，且通知您处理结果。
      </div>
      <div>
        4.1.3 您理解并同意，因您违反相关法律法规或本协议约定引发的任何后果，均由您独立承担责任、赔偿损失，与我们无关。如侵害到南京亿儒科技有限公司或他人权益的，您须自行承担全部责任和赔偿一切损失。
      </div>
      <div class="tc-black fs-20 my-6">4.2平台责任</div>
      <div>
        4.2.1 平台责任本小程序主体保证并承诺，本小程序主体系合法成立的法人，依据本协议约定向您提供相关网络服务，并会参考您提出的建议不断改善我们的服务，努力提高您的使用满意度。
      </div>
      <div class="tc-black fs-20 my-6">4.3责任限制</div>
      <div>
        4.3.1 您理解并确认，我们需要定期或不定期地对南京亿儒科技有限公司平台或相关的设备进行检修或者维护，自互联网连接能力受到全球网格稳定性，技术状态，使用者所在地与使用的网格，由力供应、政府管制、计算机病毒、黑客攻击等既存不确定性的限制，如因此类情况而造成服务在合理时间内的中断，我们无需为此承担任何责任，但会事先进行通告。
      </div>
      <div>
        4.3.2 您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，南京亿儒科技有限公司将努力在第一时间与相关单位配合，及时进行修复，但是中此给您造成的损失南京亿儒科技有限公司在法律允许的范围内免责。
      </div>
      <div>
        4.3.3 南京亿儒科技有限公司不保证为向您提供便利而设置的外部链接的准确性和完整性。同时，对于外部链接指向的不由南京亿儒科技有限公司实际控制的任何网页上的内容，南京亿儒科技有限公司不承担任何责任。
      </div>
    </div>
    <div class="my-6">
      <div class="tc-black fs-25 my-6">五、隐私政策</div>
      <div>详见 <span class="tc-link cp" style="text-decoration: underline" @click="goPrivacyPolicy">《隐私政策》</span>。</div>
    </div>
    <div class="my-6">
      <div class="tc-black fs-25 my-6">六、协议解除和终止</div>
      <div>
        6.1 如有下列情形的，我们有权单方面解除本协议，终止向您提供服务：（1）您为了非法目的而使用本服务的；（2）您使用本服务损害南京亿儒科技有限公司或其他第三方合法权益的；（3）您违反法律法规或本协议约定或违反与腾讯的其他约定的；（4）根据法律规定平台用户应提交真实信息，而您提供的资料不真实、或未能提供合理证明以证明其真实性的。
      </div>
      <div>
        6.2 您同意，除上述所述情形外，南京亿儒科技有限公司有权根据风险及自身业务运营情况需要，随时终止向您提供本服务及接口的部分及全部，因此导致您无法使用服务或服务受到限制的，南京亿儒科技有限公司不构成违约，亦不承担任何法律责任。
      </div>
      <div>
        6.3 您停用该服务，或南京亿儒科技有限公司终止向您提供本协议项下的服务后，南京亿儒科技有限公司不再为您保留原账户中与之相关的任何信息。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "userServicesAgreement",
  methods: {
    goPrivacyPolicy() {
      this.$emit('goPrivacyPolicy')
    }
  }
}
</script>

<style scoped>

</style>